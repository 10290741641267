import React from 'react'

import { Routes, Route } from "react-router-dom";
import Home from '../Pages/Home';
import Privacy from '../Pages/Privacy';
import TermsOfUse from '../Pages/TermsOfUse';


const Navigator = () => {
    return (
        <Routes>
            <Route path="*" element={<Home />}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
        </Routes>
    )
}

export default Navigator