import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MainHeader.module.css'

const MainHeader = () => {
    return (
        <div className={styles.homeHeader}>
            <div className={styles.homeHeaderContainer}>
                <div className={styles.hhcLeft}>

                </div>
                <div className={styles.hhcCentre}>
                    <div className={styles.hhcCentreContainer}>
                        <div className={styles.eachNavWrapper}>
                            <Link to={`/`}>Home</Link>
                        </div>
                        <div className={styles.eachNavWrapper}>
                            <Link to={`/`}>About Us</Link>
                        </div>
                        <div className={styles.eachNavWrapper}>
                            <Link to={`/`}>Members</Link>
                        </div>
                    </div>
                </div>
                <div className={styles.hhcRight}>
                    <Link to={`/get-in-touch`}>Get In Touch</Link>
                </div>
            </div>
        </div>
    )
}

export default MainHeader