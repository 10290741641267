import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MainFooter from '../Components/Footer/MainFooter'
import MainHeader from '../Components/Header/MainHeader'
import styles from './Home.module.css'

const Home = () => {

    return (
        <div className={styles.mainWrapper}>
            <MainHeader/>
            <div className={styles.firstPaintWrapper}>
                <div className={styles.firstPaintLeftWrapper}>
                    <div className={styles.fpOne}>
                        <div className={styles.brandTxt}>
                            <h1>Qnnox</h1>
                        </div>
                        <div className={styles.brandSubTxt}>
                            <h4>
                                The All-in-One Platform for Customer Engagement, Promotions, and Loyalty
                            </h4>
                        </div>
                    </div>
                    <div className={styles.attractiveTxt}>
                        <p>Increase revenue, efficiency, and customer satisfaction with Qnnox.</p>
                    </div>
                </div>
                <div className={styles.firstPaintRightWrapper}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/master-file-Qnnox-logo_512x512.png" alt="qnnox-logo" />
                </div>
            </div>


            <div className={styles.heroWrapper}>
                <div className={styles.heroContainer}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/Revitalizing-customer-loyalty.webp" alt="revitalize img" />
                </div>
            </div>




            <div className={styles.featureWrapper}>
                <div className={styles.featureContainer}>
                    <div className={styles.fcHeader}>
                        <div className={styles.fcTag}>FEATURES</div>
                        <div className={styles.fcTitle}>
                            Unify Your Customer Journey with Qnnox's Powerful Features
                        </div>
                        <div className={styles.fcSubtitle}>
                            Drive engagement, personalize experiences, and gain insights with our all-in-one platform.
                        </div>
                    </div>
                    <div className={styles.fcBody}>
                        <div className={styles.allFeatures}>
                            <div className={styles.eachFeatureWrapper}>
                                <h4>
                                    Loyalty Programs
                                </h4>
                            </div>

                            <div className={styles.eachFeatureWrapper}>
                                <h4>
                                    Personalized Promotions
                                </h4>
                            </div>

                            <div className={styles.eachFeatureWrapper}>
                                <h4>
                                    Marketing Automation
                                </h4>
                            </div>

                            <div className={styles.eachFeatureWrapper}>
                                <h4>
                                    Data Analytics
                                </h4>
                            </div>

                            <div className={styles.eachFeatureWrapper}>
                                <h4>
                                    Integrations with Existing Systems
                                </h4>
                            </div>


                        </div>
                    </div>

                </div>


            </div>

            <MainFooter />

        </div>
    )
}

export default Home